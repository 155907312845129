import IPlatform from './IPlatform';
import IArticle from './IArticle';
import IWishlistArticle from './IWishlistArticles';

class WishlistService {

    private accessToken = sessionStorage.getItem("accessToken");
    private customerId = sessionStorage.getItem("customerId");

    public async findByCustomerId(): Promise<any> {

        return fetch('/api/market/wishlists?customer=' + this.customerId + '&sort=name', {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response: any) => {
                return response.json();
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async findArticleByWishlistId(wishlistId: any): Promise<IPlatform> {

        return fetch('/api/market/wishlists/' + wishlistId + '?customer=' + this.customerId + '&sort=reference', {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response: any) => {
                return response.json();
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async wishlistTotal(): Promise<any> {

        return fetch('/api/market/wishlistTotal?customer=' + this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response: any) => {
                return response.json();
            })
            .catch((err: any) => {
                console.log(err);
            });
    }    

    public async addWhishlist(platformId: string, name: string): Promise<any> {


        let data = {
            "name": name,
            "customer": {
                "id": this.customerId
            },
            "platform": {
                "id": platformId
            }
        };

        return fetch('/api/market/wishlists?customer=' + this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async updateWhishlist(whislist: any): Promise<any> {


        return fetch('/api/market/wishlists/'+whislist.id+'?customer=' + this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "PUT",
            body: JSON.stringify(whislist)
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }    

    public async deleteWishlist(wishlistId: number): Promise<any> {

        return fetch('/api/market/wishlists/' + wishlistId + '?customer=' + this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "DELETE",
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async addArticleWhishlist(item: IArticle, quantity: number, platformId: number, sourceWishlistArticleId: number | undefined): Promise<any> {

        let data = {
            "article": {
                "id": item.id
            },
            "quantity": quantity
        };

        let uri = '/api/market/wishlistArticles?customer=' + this.customerId + '&platform=' + platformId;
        if(sourceWishlistArticleId !== undefined){
            uri += '&sourceWishlistArticle=' + sourceWishlistArticleId
        }

        return fetch(uri, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async deleteArticleWishlist(wishlistArticleId: number): Promise<any> {

        return fetch('/api/market/wishlistArticles/' + wishlistArticleId + '?customer=' + this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "DELETE",
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async updateArticleWhishlist(item: IWishlistArticle, wishlistId: any, quantity: number): Promise<any> {

        let data = {
            "id": item.id,
            "wishlist": {
                "id": wishlistId
            }, 
            "article": {
                "id": item.article.id
            },                       
            "quantity": quantity
        };

        return fetch('/api/market/wishlistArticles/' + item.id + '?customer=' + this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "PUT",
            body: JSON.stringify(data)
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }
}

export default WishlistService;