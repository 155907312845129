import IArticle from '../home/IArticle';

class BookmarkService {

    private accessToken = sessionStorage.getItem("accessToken");
    private customerId = sessionStorage.getItem("customerId");

    public async search (query: string, size: number | undefined, page: number | undefined, sort: string): Promise<any> {

        return fetch('/api/market/bookmarks?size=' + size + '&page=' + page + '&query=' + query + '&sort=' + sort + '&customer='+this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response: any) => {
                return response.json();
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    public async addArticleBookmark (article: IArticle, customerId: number): Promise<any> {

        let data = {
            "article": {
                "id": article.id
            },
            "customer": {
                "id": customerId
            }
        };

        return fetch('/api/market/bookmarks?customer='+this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }    

    public async deleteArticleBookmark (bookmarkId: number): Promise<any> {

        return fetch('/api/market/bookmarks/' + bookmarkId + '?customer='+this.customerId, {
            headers: {
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            method: "DELETE",
        })
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }     
}

export default BookmarkService;