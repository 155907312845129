import IPlatform from './IPlatform';
import IWishlistArticle from './IWishlistArticles';
import IShippingMethod from '../shoppingcart/IShippingMethod';

interface IWishlist {
  id: number,
  name: string,

  reference: string,
  reference2: string,


  grossAmount: number,
  discount: number,
  netAmount: number,
  totalAmountExclTaxes: number,
  totalAmountExclTaxesSelected: number,
  taxes: number,
  totalAmountInclTaxes: number,
  totalAmountInclTaxesSelected: number,
  totalGrossAmount: number,
  shippingCostsExclTaxesSelected: number,
  shippingCostsInclTaxesSelected: number,
  totalGrossAmountSelected: number,
  totalVat: number,
  totalVatSelected: number,
  customer: {
    id: number,
    siret: string,
    name: string,
  },
  platform: IPlatform,
  wishlistArticles: IWishlistArticle[],
  articleCount: number,
  articleCountSelected: number,
  totalQuantity: number,
  totalQuantitySelected: number,
  shippingMethod: IShippingMethod,

  totalPaymentAmountExclTaxesSelected: number,
  totalPaymentTaxesAmountSelected: number,
  totalPaymentAmountInclTaxesSelected: number,

  freeShippingAmount: number,
  freeShippingQuantity: number,
  remainingFreeShippingAmount: number,
  remainingFreeShippingQuantity: number,
  freeShipping: boolean,
  selectState: string,
}

export default IWishlist;


export enum SelectState {
  ALL = "ALL", PARTIAL = "PARTIAL", NONE = "NONE"
}
