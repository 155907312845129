import React from 'react';
import '../../App.css';
import { List, Row, Col, Typography, Tabs } from 'antd';

import IConfirmedSalesOrder from './IConfirmedSalesOrder';
import IOrderPlatforms from './IOrderPlatforms';
import { ConfirmedSalesOrderViewAddress, ConfirmedSalesOrderViewHeader, ConfirmedSalesOrderViewPlatformHeader, ConfirmedSalesOrderViewFooter, ConfirmedSalesOrderViewPlatformItem } from './ConfirmedSalesOrderViewUtils';
import IOrderPlatformDetails from './IOrderPlatformDetails';
import FlybyList from '../../Utils/FlybyList';
import { DefaultParameter } from '../../Utils/MarketParameterContext';

const { Text } = Typography;
const { TabPane } = Tabs;

class ConfirmedSalesOrderView extends React.Component<
  {
    confirmOrder: IConfirmedSalesOrder,
    parameters: DefaultParameter
  }, {}>{

  render() {

    return (
      <div>
        {this.props.confirmOrder !== undefined && this.props.confirmOrder.orderPlatforms !== undefined &&
          <div>
            <div style={{ fontSize: '0.95em', border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px', marginBottom: 5 }}>
              <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
                <Col span={24}>
                  <Text strong>Identifiant commande : </Text>{this.props.confirmOrder.id}<br />
                </Col>
              </Row>

              <ConfirmedSalesOrderViewAddress confirmOrder={this.props.confirmOrder} />
            </div>

            <div style={{ fontSize: '0.95em', marginTop: 5 }}>


              <Tabs tabBarStyle={{ margin: 2 }}>

                {this.props.confirmOrder.orderPlatforms.map((orderPlatforms: IOrderPlatforms) => (

                  (orderPlatforms !== undefined && orderPlatforms.orderPlatformDetails !== undefined
                    && orderPlatforms.orderPlatformDetails.length > 0) &&

                  <TabPane tab={orderPlatforms.platform?.name} key={orderPlatforms.id?.toString()} >

                    <div style={{
                      borderLeft: 'solid', borderLeftColor: '#e8e8e8', borderLeftWidth: '1px',
                      borderRight: 'solid', borderRightColor: '#e8e8e8', borderRightWidth: '1px',
                      borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px',
                    }}>

                      <ConfirmedSalesOrderViewHeader parameters={this.props.parameters} />

                      <div key={orderPlatforms.id}>
                        <ConfirmedSalesOrderViewPlatformHeader orderPlatforms={orderPlatforms} />

                        {orderPlatforms !== undefined && orderPlatforms.orderPlatformDetails !== undefined &&
                          <FlybyList
                            dataSource={orderPlatforms.orderPlatformDetails}
                            renderItem={(item: IOrderPlatformDetails) => (
                              <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
                                <ConfirmedSalesOrderViewPlatformItem item={item} parameters={this.props.parameters} />
                              </List.Item>
                            )}>
                          </FlybyList>
                        }

                      </div>
                    </div>
                  </TabPane>
                ))}
              </Tabs>





              <div style={{ marginTop: 10 }}>
                <ConfirmedSalesOrderViewFooter confirmOrder={this.props.confirmOrder} />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ConfirmedSalesOrderView;