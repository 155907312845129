/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import { ListProps } from 'antd/lib/list';
import React from 'react';
import { List } from 'antd';
import ParameterService from './ParameterService';
class FlybyList<T extends object> extends React.Component<ListProps<T>, {
  dataLoading: boolean,
  list: any[],
  showPagination: boolean,
  perPage: number,
}> {

  container: React.RefObject<HTMLDivElement>;
  page: number = 1;
  data: [] = [];
  totalPages: number = 0;

  
  private parameterService: ParameterService = new ParameterService();

  constructor(props: ListProps<T>) {
    super(props);

    this.container = React.createRef();

    this.state = {
      dataLoading: false,
      list: [],
      showPagination: true,
      perPage: 5,
    };

  }

  componentDidMount(): void {

    this.parameterService.findValue("MIN_PAGINATION_NUMBER")
    .then((value: string) => {
      if(!isNaN(Number(value)) && this.props.dataSource !== undefined && this.props.dataSource.length <= Number(value)){
        this.setState({
          showPagination: false,
          perPage: Number(value),
        });
      }
      if(!isNaN(Number(value)) && this.props.dataSource !== undefined && this.props.dataSource.length > Number(value)){
        this.setState({
          showPagination: true,
          perPage: Number(value),
        });        
      }

    });    

    this.onChange(1);
  }

  render(): JSX.Element {
    return (
      <List<T>
        bordered={false}
        loading={this.state.dataLoading}
        itemLayout="horizontal"
        size="small"
        style={{ marginBottom: 20 }}
        pagination={ !this.state.showPagination ? false : {
          onChange: page => {
            this.onChange(page)
          },
          pageSize: this.state.perPage,
          total: this.props.dataSource !== undefined ? this.props.dataSource.length : 0,
          style: { marginRight: 10 },
          size: 'small'
        }}

        dataSource={this.state.list}
        {...this.props}  />
    );
  }

  private onChange = (page: number) => {
    if(this.props.dataSource !== undefined){
      this.setState({
        dataLoading: true,
      });
  
      this.page = page;
      let offset: number = (this.page - 1) * this.state.perPage;
  
      const data: any = this.props.dataSource.slice(offset).slice(0, this.state.perPage);
  
      this.setState(
        {
          dataLoading: false,
          list: data,
        }
      );
    }
  }
}

export default FlybyList;
