import React from 'react';
import '../../App.css';
import { LeftOutlined, FrownOutlined } from '@ant-design/icons';
import { Button, Spin, notification, Typography, Row, Col } from 'antd';
import ConfirmedSalesOrderView from './ConfirmedSalesOrderView';
import IConfirmedSalesOrder from './IConfirmedSalesOrder';
import IStep from './IStep';
import SalesOrderService from './SalesOrderService';
import ParameterService from '../../Utils/ParameterService';
import { PaymentMethod } from '../../Utils/PaymentUtils';
import { MarketParameterContext } from '../../Utils/MarketParameterContext';

const { Title } = Typography;

class Step6 extends React.Component<
  {
    stepConf: IStep | undefined,
    values: any,
    onNextStep: any,
    refreshWishlist: any,
    refreshWishlistTotal: any,
    clearSalesOrder: any
  },
  {
    confirmOrder: IConfirmedSalesOrder | undefined,
    dataLoading: boolean,
    payementOrder: boolean,
    error: boolean
  }>{

  private salesOrderService: SalesOrderService = new SalesOrderService();
  private parameterService: ParameterService = new ParameterService();

  constructor(props: { stepConf: IStep | undefined, values: any, onNextStep: any, refreshWishlist: any, refreshWishlistTotal: any, clearSalesOrder: any }) {
    super(props);

    this.state = {
      confirmOrder: undefined,
      dataLoading: false,
      payementOrder: false,
      error: false,
    };
  }

  componentDidMount() {
    this.parameterService.findValue("PAYMENT_ORDER")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            payementOrder: JSON.parse(value)
          });
        }
        else {
          this.setState({
            payementOrder: false
          });
        }

        this.onConfirmSalesOder();
      });
  }

  render() {

    return (
      <MarketParameterContext.Consumer>
        {(parameters) =>
          <Spin spinning={this.state.dataLoading}>

            {this.state.confirmOrder === undefined &&
              <div>
                {!this.state.error &&
                  <div style={{ marginTop: 100, marginBottom: 100, fontSize: 24 }}>Commande en cours de traitement...</div>
                }
                {this.state.error &&
                  <div style={{ marginTop: 100, marginBottom: 100, fontSize: 24 }}><FrownOutlined /> Impossible de valider la commande.</div>
                }
                <div style={{ float: "right", marginBottom: 10, marginTop: 10 }}>
                  <Button type="primary" size="large" onClick={() => this.props.onNextStep(1)} style={{ marginRight: 5 }}><LeftOutlined />Retour au panier</Button>
                </div>
              </div>
            }

            {this.state.confirmOrder !== undefined &&
              <div>

                <Row>
                  <Col span={24}>
                    <div style={{ float: "right", marginBottom: 10, marginTop: 10 }}>
                      <Button type="primary" size="large" onClick={() => this.props.onNextStep(this.props.stepConf?.prevAction.prevStep)} hidden={!this.props.stepConf?.prevAction.enable} style={{ marginRight: 5 }}><LeftOutlined />{this.props.stepConf?.prevAction.text}</Button>
                      <Button type="primary" size="large" onClick={() => this.props.onNextStep(this.props.stepConf?.nextAction.nextStep)} hidden={!this.props.stepConf?.nextAction.enable} style={{ marginRight: 5 }}><LeftOutlined />{this.props.stepConf?.nextAction.text}</Button>
                    </div>
                  </Col>
                </Row>

                <Title level={4}>Votre commande est enregistrée.</Title>
                <p>Les prochaines étapes : </p>
                <ul>
                  {this.state.payementOrder &&
                    <li>Vérification du paiement</li>
                  }
                  <li>Traitement de commande</li>
                  <li>Expédition</li>
                </ul>
                <br />
                <br />

                <ConfirmedSalesOrderView confirmOrder={this.state.confirmOrder} parameters={parameters} />
                <div style={{ float: "right", marginBottom: 10, marginTop: 10 }}>
                  <Button type="primary" size="large" onClick={() => this.props.onNextStep(this.props.stepConf?.prevAction.prevStep)} hidden={!this.props.stepConf?.prevAction.enable} style={{ marginRight: 5 }}><LeftOutlined />{this.props.stepConf?.prevAction.text}</Button>
                  <Button type="primary" size="large" onClick={() => this.props.onNextStep(this.props.stepConf?.nextAction.nextStep)} hidden={!this.props.stepConf?.nextAction.enable} style={{ marginRight: 5 }}><LeftOutlined />{this.props.stepConf?.nextAction.text}</Button>
                </div>
              </div>
            }
          </Spin>
        }
      </MarketParameterContext.Consumer>
    );
  }

  private onConfirmSalesOder = () => {

    this.setState({
      dataLoading: true,
      error: false,
    });


    if (this.state.payementOrder && this.props.values.salesOrder.paymentMethod === PaymentMethod.CREDIT_CARD) {
      this.setState({
        confirmOrder: this.props.values.salesOrder,
        dataLoading: false
      });
      this.props.refreshWishlist();
      this.props.refreshWishlistTotal();
      this.props.clearSalesOrder();
    }
    else {
      this.salesOrderService.postOrder(this.props.values.salesOrder)
        .then((response: any) => {
          if (response.status === 201) {
            return response.json();
          }
          else {
            notification.error({ message: 'Commande', description: 'Impossible de valider la commande.', duration: 15 });
            return undefined;
          }
        })
        .then((result: any) => {
          if (result === undefined) {
            this.setState({
              confirmOrder: undefined,
              dataLoading: false
            });
          }
          else {
            this.setState({
              confirmOrder: result,
              dataLoading: false
            });
            this.props.refreshWishlist();
            this.props.refreshWishlistTotal();
            this.props.clearSalesOrder();
          }
        })
        .catch((err: any) => {
          this.setState({
            confirmOrder: undefined,
            dataLoading: false,
            error: true
          });
          notification.error({ message: 'Commande', description: 'Impossible de valider la commande.', duration: 15 });
        });
    }
  }
}

export default Step6;