import React from 'react';
import '../../App.css';
import { MailOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import parse from 'html-react-parser';


import FlybyUtils from '../../Utils/FlybyUtils';
import CustomerService from './CustomerService';
import IData from '../../Utils/IData';
import ParameterService from '../../Utils/ParameterService';

class Mail extends React.Component<{},
  {
    data: IData,
    dataLoading: boolean,
    page: string | undefined
  }
  >{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private customerService: CustomerService = new CustomerService();
  private parameterService: ParameterService = new ParameterService();

  private columns = [
    {
      title: 'Contact',
      dataIndex: 'fullName',
      width: 250,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 250,
    },
    {
      title: '',
      width: 50,
      render: (text: string, record: any) =>
        <div style={{ textAlign: "right" }}>
          <Button href={'mailto:' + record.email} ><MailOutlined />Contacter</Button>
        </div>,
    }
  ];

  constructor(props: {}) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      page: undefined
    };
  }

  componentDidMount() {

    this.parameterService.findValue("APP_CONTACTS")
      .then((page: string) => {
        if (page !== undefined) {
          this.parameterService.getPublicHtmlPage(page)
            .then((content: string) => {
              this.setState({
                page: content
              });
            });
        }

        if (page === undefined) {
          this.searchCustomerContact('', 100, 0, 'id');
        }
      });
  }

  render() {

    return (
      <div>
        <h3>Mes contacts</h3>

        {this.state.page === undefined &&
          <Table rowKey="email" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
            pagination={false} style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 0px 1px' }} />
        }
        {this.state.page !== undefined &&
          <div>{parse(this.state.page)}</div>
        }
      </div >
    );
  }

  private searchCustomerContact = (query: string, pageSize: number | undefined, current: number | undefined, sort: string) => {
    this.setState({
      dataLoading: true,
    });

    this.customerService.search(query, pageSize, current, sort)
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false
          });
        }
      });
  }
}

export default Mail;        