
import React from 'react';
import IArticle from '../IArticle';
import IArticleEquivalence from '../IArticleEquivalence';
import ArticleService from '../ArticleService';
import FlybyUtils from '../../../Utils/FlybyUtils';
import IData from '../../../Utils/IData';
import SimpleArticleCard from './SimpleArticleCard';
import { Divider, List } from 'antd';



class DetailArticle extends React.Component<
  {
    article: IArticle,
    onChangeSelect: any,
    onChangeQuantity: any,
  },
  {
    dataLoading: boolean,
    equivalences: IData,
  }>{

  private articleService: ArticleService = new ArticleService();
  private flybyUtils: FlybyUtils = new FlybyUtils();

  constructor(props: { article: IArticle, onChangeSelect: any, onChangeQuantity: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      equivalences: this.flybyUtils.getEmptyData(),
    };
  }

  componentDidMount() {
    this.searchArticles(this.props.article, 10, 0);
  }

  render() {
    return (
      <div>
        <SimpleArticleCard article={this.props.article} onChangeQuantity={this.props.onChangeQuantity}
          onChangeSelect={this.props.onChangeSelect}
          cardStyle={{ marginBottom: 0, border: 2, borderColor: "#1890ff", borderStyle: "solid" }} />

        <Divider style={{ fontSize: 16, fontWeight: 500, width: "100%", textAlign: "center", marginBottom: 15, marginTop: 15 }} >
          LES EQUIVALENCES
        </Divider>

        <List
          style={{ marginTop: 10 }}
          grid={{ column: 1 }}
          size="small"
          dataSource={this.state.equivalences.content}
          pagination={{ onChange: this.onChangeList, current: (this.state.equivalences.number + 1), pageSize: 10, total: this.state.equivalences.totalElements }}
          renderItem={(item: IArticleEquivalence) => (
            <List.Item key={item.id} style={{ marginBottom: 0 }} >
              <SimpleArticleCard article={item.equivalentArticle} onChangeQuantity={this.props.onChangeQuantity}
                onChangeSelect={this.props.onChangeSelect}
                cardStyle={{ marginBottom: 0 }} />
            </List.Item>
          )}
        />
      </div>
    );
  }

  private onChangeList = (pageNumber: number, pageSize?: number) => {
    this.searchArticles(this.props.article, 10, pageNumber - 1);
  }


  private searchArticles = (article: IArticle, pageSize: number | undefined, current: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.articleService.findEquivalences(article.id, pageSize, current)
      .then((data: any) => {

        if (data !== undefined) {

          this.setState({
            equivalences: data,
            dataLoading: false
          });
        }
      });

  }

}

export default DetailArticle;