import React from 'react';
import { Skeleton, Statistic } from 'antd';

import IArticle from '../IArticle';
import FlybyUtils from '../../../Utils/FlybyUtils';
import { BasketContext } from '../../../Utils/BasketProvider';


class ArticlePrice extends React.Component<{ item: IArticle, loading: boolean }, {}>{

  private flybyUtils: FlybyUtils = new FlybyUtils();

  render() {
    return (

      <BasketContext.Consumer>
        {(basket) =>
          <>
            {basket.userRole !== 'ROLE_GUEST' && this.props.item.availability !== undefined &&
              <Skeleton loading={this.props.item.loading} active>

                <Statistic title="Montant net" value={this.flybyUtils.numberFormat(this.props.item.availability.netAmount, 2)} suffix="€ HT" />
                {this.props.item.availability.netAmount < this.props.item.availability.netAmountInclTaxes &&
                  <span style={{ fontSize: 14, color: '#bfbfbf', marginTop: 25 }} >{this.flybyUtils.numberFormat(this.props.item.availability.netAmountInclTaxes, 2)} € TTC</span>
                }
              </Skeleton>

            }
          </>
        }
      </BasketContext.Consumer>
    );
  }
}

export default ArticlePrice;